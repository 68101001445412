import React from "react"

import Styles from "./LoadingScreen.module.css"

import Logo from "@/components/global/Logo"
import { MediumLoadingIndicator } from "@/components/global/LoadingIndicator/LoadingIndicator"

const LoadingScreen: React.FC = () => {
	return (
		<div className={Styles.screen}>
			<div className={Styles.content}>
				<Logo className={Styles.logo} />

				<MediumLoadingIndicator />
			</div>
		</div>
	)
}

export default LoadingScreen
