import React, { Suspense } from "react"

import LoadingScreen from "./components/global/LoadingScreen/LoadingScreen"

const Render = import.meta.env.VITE_LANDING
	? React.lazy(() => import("./LandingApp"))
	: React.lazy(() => import("./BaseStack"))

const App: React.FC = () => {
	return (
		<Suspense fallback={<LoadingScreen />}>
			<Render />
		</Suspense>
	)
}

export default App
