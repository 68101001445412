/* eslint-disable no-template-curly-in-string */
import { DeepRequired } from "utility-types"
import { LocaleObject } from "yup/lib/locale"

export const defaultValidationMessages: DeepRequired<LocaleObject> = {
	mixed: {
		default: "Please use a valid value",
		oneOf: "Please use a valid value",
		defined: "This field is required",
		notOneOf: "Please use a valid value",
		notType: "Please use a valid value",
		required: "This field is required",
	},
	string: {
		length: "This field must be exactly ${length} characters",
		min: "This field must be at least ${min} characters",
		max: "This field must be at most ${max} characters",
		matches: 'This field must match the following: "${regex}"',
		email: "This field must be a valid email",
		url: "This field must be a valid URL",
		uuid: "This field must be a valid UUID",
		trim: "This field must be a trimmed string",
		lowercase: "This field must be a lowercase string",
		uppercase: "This field must be a upper case string",
	},
	number: {
		min: "This field must be greater than or equal to ${min}",
		max: "This field must be less than or equal to ${max}",
		lessThan: "This field must be less than ${less}",
		moreThan: "This field must be greater than ${more}",
		positive: "This field must be a positive number",
		negative: "This field must be a negative number",
		integer: "This field must be an integer",
	},
	date: {
		min: "Please select a date after ${min}",
		max: "Please select a date before ${max}",
	},
	boolean: {
		isValue: "This field must be ${value}",
	},
	object: {
		noUnknown: "This field has unspecified keys: ${unknown}",
	},
	array: {
		min: "Please select at least ${min} items",
		max: "Please select not more than ${max} items",
		length: "Please select ${length} items",
	},
}
